import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {ActivityLogsModel} from '../models/activity-logs.model';

interface ActivityLogsResponse {
  data: {
    data: ActivityLogsModel[];
    pagination: {
      total: number;
      page: number;
      limit: number;
      totalPages: number;
    };
  };
  error: any;
  success: any;
}

@Injectable({
  providedIn: 'root'
})
export class ActivityLogsService {

  uriLogs = environment.apiURL + 'admin/logs';

  constructor(private http: HttpClient) { }

  getAll(query?: {page: number, limit: number, search?: string}): Observable<ActivityLogsResponse> {
      return this.http.get<ActivityLogsResponse>(this.uriLogs, {
        params: query,
      });
    }
}
