import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {Contact} from './contact.model';
import * as ClientService from '../../../../shared/services/contacts/contacts.service';
import {environment} from '../../../../../environments/environment';
import {ContactQuery} from '@shared/types/contacts-types'

@Injectable()
export class ContactsService implements Resolve<any>
{
    onContactsChanged: BehaviorSubject<any>;
    onPageChanged: BehaviorSubject<any>;

    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    contacts: Contact[];
    page: any = {
        limit: 20,
        count: 0,
        offset: 0,
        currentPage: 1,
        totalPages: 0
    };
    user: any;
    selectedContacts: string[] = [];
    searchText: string;
    filterBy = 'all';

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private clientService: ClientService.ContactsService
    )
    {
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onPageChanged = new BehaviorSubject([]);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);

        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            resolve(true)
        //     Promise.all([
        //         this.getAllPaginated({page: 1, limit: 5}),
        //         // this.getUserData()
        //     ]).then(
        //         ([files]) => {

        //             // this.onSearchTextChanged.subscribe(searchText => {
        //             //     this.searchText = searchText;
        //             //     this.getContacts();
        //             // });

        //             // this.onFilterChanged.subscribe(filter => {
        //             //     this.filterBy = filter;
        //             //     this.getContacts();
        //             // });

        //             resolve(true);

        //         },
        //         reject
        //     );
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'contacts', {isDeleted : 'none', filterBy : this.filterBy, searchText: this.searchText})
                    .subscribe((response: any) => {
                        if (response['success'] && response['data']){
                            this.contacts = response['data'];
                        }

                        this.contacts = this.contacts.map(contact => {
                            return new Contact(contact);
                        });

                        this.onContactsChanged.next(this.contacts);
                        resolve(this.contacts);
                    }, reject);
            }
        );
    }


    getAllPaginated(query?: {page: number, limit: number}): Promise<any> {
        const uri = `${environment.apiURL}admin/contacts`;
        return new Promise((resolve, reject) => {
            this._httpClient.get<any>(uri, {
                params: query ? { page: query.page, limit: query.limit } : {},
            }).subscribe((response: any) => {
                if (response['success'] && response['data']){
                    this.contacts = response['data']['data'];
                    this.page.total = response['data']['total'];
                    this.page.limit = response['data']['limit'];
                    this.page.currentPage = response['data']['page'];
                    this.page.totalPages = response['data']['totalPages'];
                }

                this.contacts = this.contacts.map(contact => {
                    return new Contact(contact);
                });

                this.onContactsChanged.next(this.contacts);
                this.onPageChanged.next(this.page);
                resolve(this.contacts);

                console.log(response['data'])
            }, reject)
        });
        
    }

    getAllPaginatedV2(query: ContactQuery): Promise<any> {
        return new Promise((resolve, reject) => {
            this.clientService.getAllPaginated(query).subscribe((response: any) => {
                if (response['success'] && response['data']){
                    this.contacts = response['data']['data'];
                    this.page.total = response['data']['total'];
                    this.page.limit = response['data']['limit'];
                    this.page.currentPage = response['data']['page'];
                    this.page.totalPages = response['data']['totalPages'];
                }

                resolve({
                    page: this.page,
                    contacts: this.contacts
                })

                // this.contacts = this.contacts.map(contact => {
                //     return new Contact(contact);
                // });

                // this.onContactsChanged.next(this.contacts);
                // this.onPageChanged.next(this.page);
                // resolve(this.contacts);

                // console.log(response['data'])
            }, reject)
        });
        
    }

    /*
     * Get user data
     *
     * @returns {Promise<any>}
     */
    getUserData(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get('api/contacts-user/5725a6802d10e277a0f35724')
                    .subscribe((response: any) => {
                        this.user = response;
                        this.onUserDataChanged.next(this.user);
                        resolve(this.user);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void
    {
        // First, check if we already have that contact as selected...
        if ( this.selectedContacts.length > 0 )
        {
            const index = this.selectedContacts.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedContacts.length > 0 )
        {
            this.deselectContacts();
        }
        else
        {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void
    {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Update contact
     *
     * @param contact
     * @returns {Promise<any>}
     */
    updateContact(contact): Promise<any>
    {
        return new Promise((resolve, reject) => {

            this._httpClient.post(environment.apiURL + 'contacts/updateUserData', contact)
                .subscribe((response: any) => {
                    if (response.success) {
                        this.contacts = this.contacts.filter( item => {
                            if (item.id === contact.userId) {
                                item.name = contact.rawData.username;
                                item.email = contact.rawData.email;
                            }
                            return item;
                        });
                        this.onContactsChanged.next(this.contacts);
                    }
                    resolve(response);
                });
        });
    }

    /**
     * Update user data
     *
     * @param userData
     * @returns {Promise<any>}
     */
    updateUserData(userData): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/contacts-user/' + this.user.id, {...userData})
                .subscribe(response => {
                    // this.getUserData();
                    this.getContacts();
                    resolve(response);
                });
        });
    }

    /**
     * Deselect contacts
     */
    deselectContacts(): void
    {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contact): void
    {
        this.clientService.clearUsers([contact.id]).subscribe(data => {
            if (data['success']){
                const contactIndex = this.contacts.indexOf(contact);
                this.contacts.splice(contactIndex, 1);
                this.onContactsChanged.next(this.contacts);
            }
        });

    }

    changeContributor(contri, contact): void
    {
       this.clientService.updateContributor(contri, contact.id).subscribe();
    }

    restoreContact(contact): void
    {
        this.clientService.clearUsers([contact.id], false).subscribe(data => {
            if (data['success']){
                const contactIndex = this.contacts.indexOf(contact);
                this.contacts.splice(contactIndex, 1);
                this.onContactsChanged.next(this.contacts);
            }
        });
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void
    {
        for ( const contactId of this.selectedContacts )
        {
            const contact = this.contacts.find(_contact => {
                return _contact.id === contactId;
            });
            const contactIndex = this.contacts.indexOf(contact);
            this.contacts.splice(contactIndex, 1);
        }
        this.onContactsChanged.next(this.contacts);
        this.deselectContacts();
    }

    requestResetPassword(email): Promise<any> {
        return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'auth/requestResetPassword', { email })
                    .subscribe(response => {
                    resolve(response);
                });
            }
        );
    }

}
