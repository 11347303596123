<div id="contacts" class="page-layout carded fullwidth inner-scroll">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon mr-16"
                              [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">account_box
                    </mat-icon>
                    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        Contacts
                    </span>
                </div>

            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

                <div class="search-wrapper mt-16 mt-sm-0">

                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input placeholder="Search for a contact" #filter (keyup)="onSearchChange(filter.value)" >
                    </div>
                </div>
                <div class="ml-sm-32" fxLayout="row">
                    <mat-checkbox class="remember-me" aria-label="Contributor" color="warn"
                                  [(ngModel)]="contributorOnly" (change)="onSelectContributor()">
                        Contributor
                    </mat-checkbox>
                </div>
                <div class="ml-sm-32" fxLayout="row">
                    <mat-checkbox class="remember-me" aria-label="DeleteUser" color="warn" [(ngModel)]="isDeletedUserOnly"
                        (change)="onSelectDeleteUser()">
                        Deleted user
                    </mat-checkbox>
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- Contributor check -->

            <!--/ Contributor -->
        </div>
        <div class="content-card">
            <mat-table class="contacts-table"
                       #table [dataSource]="contacts"
                       [@animateStagger]="{value:'50'}"
                       matSort>

                <!-- Avatar Column -->
                <ng-container matColumnDef="avatar">
                    <mat-header-cell *matHeaderCellDef class="width100"></mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <img class="avatar" *ngIf="contact.avatar" [alt]="contact.name"
                             [src]="contact.avatar"
                             (error)="this.handleMissingImage($event)"
                             tooltip="<img src='{{contact.avatar}}'>"
                             showDelay="0" hideDelay="0"
                             placement="right" animationDuration="0"/>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <a
                            (click)="editContact(contact)">
                            {{ contact.name }}</a>
                    </mat-cell>
                </ng-container>

                <!-- Job Title Column -->
                <ng-container matColumnDef="contributor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="contributor" class="width100">
                        Contributor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <mat-slide-toggle
                            [checked]="contact.contributor"
                            (change)="changeContributor($event,contact)">
                        </mat-slide-toggle>
                    </mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <p class="email text-truncate" (click)="editContact(contact)">
                            {{ contact.email }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Created At Column -->
                <ng-container matColumnDef="createdAt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">CreatedAt</mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <span>{{ contact.createdAt | date:'medium' }}</span>
                    </mat-cell>
                </ng-container>

                <!-- Join Method Column -->
                <ng-container matColumnDef="join">
                    <mat-header-cell *matHeaderCellDef class="width100">Join Method</mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <img width="30" *ngIf="contact?.joinMethod?.firstM"
                             [src]="'/assets/images/siteIcon/' + contact.joinMethod.firstM + '.png'"/>
                    </mat-cell>
                </ng-container>

                <!-- Join Method Column -->
                <ng-container matColumnDef="last">
                    <mat-header-cell *matHeaderCellDef class="width100">Last Method</mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <img width="30" *ngIf="contact?.joinMethod?.lastM"
                             [src]="'/assets/images/siteIcon/' + contact.joinMethod.lastM + '.png'"/>
                    </mat-cell>
                </ng-container>

                <!-- Last Login Column -->
                <ng-container matColumnDef="lastLogin">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="lastLogin">Last Login
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <span>{{ contact.lastLogin | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                    </mat-cell>
                </ng-container>

                <!-- Login Count Column -->
                <ng-container matColumnDef="loginCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="loginCount" class="width100">Login Count
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <span class="quantity-indicator text-truncate"
                              [ngClass]="{'red-500':contact.loginCount <= 5, 'amber-500':contact.loginCount > 5 && contact.loginCount <= 25,'green-600':contact.loginCount > 25}">
                        </span>
                        {{ contact.loginCount }}
                    </mat-cell>
                </ng-container>

                <!-- Active Column -->
                <ng-container matColumnDef="isFlagged">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="isFlagged" class="width100">Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <mat-icon *ngIf="!contact.isFlagged" class="active-icon green-600 s-16">check</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef class="width100"></mat-header-cell>
                    <mat-cell *matCellDef="let contact" class="width100">
                        <div fxFlex="row" fxLayoutAlign="end center">
                            <button mat-mini-fab color="warn" (click)="deleteContact(contact)"
                                    *ngIf="!contact.isDeleted">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" (click)="restoreContact(contact)"
                                    *ngIf="contact.isDeleted">
                                <mat-icon>restore</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let contact; columns: displayedColumns;"
                         class="contact"
                         matRipple
                         [ngClass]=""
                         [@animate]="{value:'*',params:{y:'100%'}}">
                </mat-row>
            </mat-table>
            

            <mat-paginator 
                #paginator
                [length]="page.total"
                [pageIndex]="page.page - 1"
                [pageSize]="page.limit"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="onPageChange($event)">
            </mat-paginator>

        </div>
    </div>
</div>



