<div id="ngx-datatable" class="page-layout simple fullwidth docs">

  <!-- HEADER -->
  <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div class="h2 mt-16">Activity logs</div>
    </div>

      <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

          <div class="search-wrapper mt-16 mt-sm-0">
              <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>search</mat-icon>
                  <input placeholder="Search for a contact" #filter (keyup)="onSearchChange(filter.value)" >
              </div>
          </div>
      </div>
  </div>

  <!-- CONTENT -->
  <div class="content_sys p-24" fusePerfectScrollbar>
    <ngx-datatable class="material" [rows]="data" [loadingIndicator]="loadingIndicator" [headerHeight]="40"
      [footerHeight]="56" [rowHeight]="50" [reorderable]="reorderable"
      [columnMode]="'force'" [externalPaging]="true" [count]="page.count" [offset]="page.currentPage"
      [limit]="page.limit" (page)="pageCallback($event)">
      <ngx-datatable-column name="Timestamp" prop="timestamp" [width]="20"></ngx-datatable-column>
      <ngx-datatable-column name="Message" prop="message" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column name="Details">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <button mat-mini-fab color="accent" (click)="showDetails(row, modalTemplate)"
              [ngStyle]="{ backgroundColor: row.hide ? '#FF1744' : '#039be5' }">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>