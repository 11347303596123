import {Injectable} from '@angular/core';
import {HttpClient, HttpParams } from '@angular/common/http';
import {Report} from '../../models/report.model';
import {map} from 'rxjs/operators';
import {Observable, BehaviorSubject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ContactQuery} from '@shared/types/contacts-types'
@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    uri = environment.apiURL + 'contacts/';
    uriAdmin = `${environment.apiURL}admin`
    onMsg: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        this.onMsg = new BehaviorSubject<any>(null);
    }

    public setOnMsg(param): void {
        this.onMsg.next(param);
    }

    getAll(): Observable<any> {
        return this.http.post<Report[]>(this.uri, {}).pipe(map(res => res['data']));
    }
    
    getAllPaginated(query: ContactQuery): Observable<any> {
        const uri = `${this.uriAdmin}/contacts`;
        let params = new HttpParams();
        Object.entries(query).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                params = params.append(key, value.toString());
            }
        });

        return this.http.get<Report>(uri, { params });
    }

    getTagList(): Observable<any> {
        return this.http.get(this.uri + 'tagList').pipe(map(res => res['data']));
    }

    deletePosts(params): Observable<any> {
        return this.http.post(this.uri + 'deletePosts', params).pipe(map(res => res['data']));
    }

    removeTagList(params): Observable<any> {
        return this.http.post(this.uri + 'removeTagList', params).pipe(map(res => res['data']));
    }

    updateContributor(checked = true, uid = ''): Observable<any> {
        return this.http.post(this.uri + 'updateContributor', {checked: checked, uid: uid});
    }

    modifyTag(id, value, type): Observable<any> {
        return this.http.post(this.uri + 'modifyTag', {id: id, value: value, type: type});
    }

    createTag(param): Observable<any> {
        return this.http.post(this.uri + 'createTag', param);
    }

    clearUsers(param, isDeleted = true): Observable<any> {
        return this.http.post(this.uri + 'clearUsers', {users: param, isDeleted: isDeleted});
    }

    getPayouts(param = {}): Observable<any> {
        return this.http.post(this.uri + 'getPayout', param).pipe(map(res => res['data']));
    }

    acceptPayout(payInfo = {}): Observable<any> {
        return this.http.post(this.uri + 'acceptPay', payInfo);
    }

    getBalanceFromNN(uid): Observable<any> {
        return this.http.post(environment.apiURL + 'user/getBalanceFromNN', {uid: uid});
    }
}
