import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from '../main/content/auth/login/login.component';
import {ReportComponent} from '../main/content/home/report/report.component';
import {AuthGuard} from '../shared/guards/auth/auth.guard';
import {ContactsComponent} from '../main/content/home/contacts/contacts.component';
import {PostsComponent} from '../main/content/home/posts/posts.component';
import {TagsComponent} from '../main/content/home/tags/tags.component';
import {SettingsComponent} from '../main/content/home/settings/settings.component';
import {PayoutComponent} from '../main/content/home/payout/payout.component';
import {ContactsService} from '../main/content/home/contacts/contacts.service';
import {SyslogComponent} from '../main/content/home/syslog/syslog.component';
import {ContactsContactListComponent} from '../main/content/home/contacts/contact-list/contact-list.component';
import {LoginLogComponent} from '../main/content/home/login-log/login-log.component';
import {AnalyticsComponent} from '../main/content/home/analytics/analytics.component';
import {AdminCommentsComponent} from '../main/content/home/hide-comments/comments.component';
import {HideUserCommentComponent} from '../main/content/home/hide-comments/hide-user-comment/hide-user-comment.component';
import {ActivityLogsComponent} from '../main/content/home/activity-logs/activity-logs.component'

const routers: Routes = [
    // { path: '**', redirectTo: '', pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'report',
        component: ReportComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'contacts',
        component: ContactsContactListComponent, data: {title: ''},
        canActivate: [AuthGuard],
        resolve: {
            contacts: ContactsService
        }
    },
    {
        path: 'posts',
        component: PostsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'tags',
        component: TagsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'comments',
        component: AdminCommentsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'hide-user-comments',
        component: HideUserCommentComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'activity-logs',
        component: ActivityLogsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'payout',
        component: PayoutComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'syslog',
        component: SyslogComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'loginlog',
        component: LoginLogComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/anaylytics',
        component: AnalyticsComponent,
        data: {title: ''},
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'dashboard/anaylytics'
    },
];


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routers),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
