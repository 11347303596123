import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-activity-log-dialog',
  templateUrl: './activity-log-dialog.component.html',
  styleUrls: ['./activity-log-dialog.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ActivityLogDialogComponent {

  safeHtmlContent: SafeHtml;
  content: any;
  title: string;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) {
    this.title = data.title;
    this.content = this.data.description;
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }
}
