import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivityLogsService } from "@shared/services/activity-logs.service";
import { ActivityLogsModel } from "@shared/models/activity-logs.model";
import { MatDialog } from "@angular/material/dialog";
import { ActivityLogDialogComponent } from "./activity-log-dialog/activity-log-dialog.component";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

interface ActivityLogsResponse {
    data: {
        data: ActivityLogsModel[];
        pagination: {
            total: number;
            page: number;
            limit: number;
            totalPages: number;
        };
    };
    error: any;
    success: any;
}

@Component({
    selector: "app-activity-logs",
    templateUrl: "./activity-logs.component.html",
    styleUrls: ["./activity-logs.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ActivityLogsComponent {
    @Input() data: any[];
    private searchSubject: Subject<string> = new Subject<string>();
    page = {
        limit: 20,
        count: 0,
        offset: 0,
        currentPage: 1,
        totalPages: 0,
    };

    reorderable: boolean;
    loadingIndicator: boolean = true;
    searchText: string = '';

    private _unsubscribeAll: Subject<any>;

    constructor(
        private activityLogsService: ActivityLogsService,
        private dialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.handleGetActivityLogs({ page: 1, limit: 20, ...(this.searchText && {search: this.searchText}) });
        this.searchSubject.pipe(debounceTime(300)).subscribe((searchText) => {
            this.searchText = searchText;
            this.handleGetActivityLogs({ page: 1, limit: 20, ...(this.searchText && {search: this.searchText}) });
        });
    }

    pageCallback(event: any): void {
        const currentPage = event.offset + 1;
        this.handleGetActivityLogs({
            page: currentPage,
            limit: this.page.limit,
            ...(this.searchText && {search: this.searchText})
        });
    }

    onSearchChange(searchText: string) {
        this.searchSubject.next(searchText);
    }

    showDetails(data: any) {

        const typeAllows = [
            "system_email",
            "view_post",
            "paid_content",
            "post_content",
            "delete_content",
            "like_content",
            "unlike_content",
            "bookmark_content",
            "unbookmark_content",
            "comment_content",
        ];
        this.dialog.open(ActivityLogDialogComponent, {
            data: {
                title: data?.title,
                description: data?.description,
            },
            width: typeAllows.includes(data?.type) ? "700px" : "500px",
        });
    }

    handleGetActivityLogs(query?: { page: number; limit: number, search?: string }) {
        this.activityLogsService
            .getAll(query)
            .subscribe((response: ActivityLogsResponse) => {
                if (response?.success) {
                    // Update pagination data based on API response
                    this.page.count = response.data.pagination.total;
                    this.page.limit = response.data.pagination.limit;
                    this.page.currentPage = response.data.pagination.page - 1;
                    this.page.totalPages = response.data.pagination.totalPages;
                    this.data = response.data.data;
                }
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
